<template>
  <div class="container-fluid h-650">
    <tgl-card-header>
      <h1>
        {{
          $route.params.department
            ? $t("department.departmentForm.formTitleEdit")
            : $t("department.departmentForm.formTitleCreate")
        }}
      </h1>
    </tgl-card-header>
    <tgl-card-content>
      <div
        class="
          row
          justify-center
          align-center
          w-100
          department-detail-container
        "
      >
        <v-form ref="form" class="w-100">
          <div class="col">
            <div class="col-6 pt-0 pb-0">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10 pt-0 pb-0">
                  <TGCTextField
                    :label="$t('department.departmentForm.name')"
                    v-model="form.name"
                    :rules="[
                      rules.required,
                      (value) => rules.maxLength(value, 25),
                      (value) => rules.minLength(value, 5),
                    ]"
                    counter="25"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.departmentForm.name") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-12 pt-0 pb-0">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10 pt-0 pb-0">
                  <v-textarea
                    :label="$t('department.departmentForm.description')"
                    placeholder="Descripción del departamento"
                    v-model="form.description"
                    :rules="[(value) => rules.maxLength(value, 250)]"
                    counter="250"
                  ></v-textarea>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.departmentForm.description") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="row justify-end">
              <tgl-save-button
                ref="saveBtn"
                :entity="entity"
                :dispatch="entity.dispatch"
                :action="editMode ? 'editado' : 'creado'"
                :redirect="entity.redirect"
                :validateForm="handleSubmit"
              ></tgl-save-button>
            </div>
          </div>
        </v-form>
      </div>
    </tgl-card-content>
  </div>
</template>

<script>
import TglCardContent from "@/components/Cards/TglCardContent.vue";
import TglCardHeader from "@/components/Cards/TglCardHeader.vue";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";
import form from "@/mixins/form";
export default {
  name: "DepartmentForm",
  components: { TglCardHeader, TglCardContent, TglSaveButton, TGCTextField },
  mixins: [form],
  data() {
    return {
      form: {
        name: "",
        description: "",
      },
      editMode: false,
    };
  },
  computed: {
    entity() {
      let params = {
        name: this.form.name,
        description: this.form.description,
      };
      return {
        name: "departamento",
        params: this.editMode
          ? { ...params, id: this.$route.params.department }
          : params,
        dispatch: this.editMode
          ? "department/editDepartment"
          : "department/createDepartment",
        redirect: { name: "departments" },
      };
    },
  },
  methods: {
    getDepartment() {
      this.$store
        .dispatch("department/getDepartment", this.$route.params.department)
        .then((data) => {
          this.form.name = data.name;
          this.form.description = data.description;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSubmit() {
      return this.$refs.form.validate();
    },
    editDepartment() {
      this.$store
        .dispatch("department/editDepartment", {
          id: this.$route.params.department,
          name: this.form.name,
          description: this.form.description,
        })
        .then((data) => {
          if (data) {
            this.form.name = data.name;
            this.form.description = data.description;
            this.$toast.success("Department has been created");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.success("Something went wrong");
        });
    },
  },
  mounted() {
    if (this.$route.params.department) {
      this.editMode = true;
      this.getDepartment();
    }
  },
};
</script>
