<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <router-link to="/departments">Departments</router-link> >
      <span
        v-text="$route.params.department ? $t('department.departmentForm.formTitleEdit') : $t('department.departmentForm.formTitleCreate')"
      ></span>
    </div>
    <div class="md-layout-item md-medium-size-100 md-size-100">
      <department-form :data-background-color="getTheme"> </department-form>
    </div>
  </div>
</template>

<script>
import DepartmentForm from "../components/Departments/DepartmentForm";
import { mapGetters } from "vuex";

export default {
  components: {
    DepartmentForm,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>